import 'stylesheets/pages/home.scss';

import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

// "Detect" Touchscreen

const mqlHover = window.matchMedia('(hover: none)');
const isTouchScreen = mqlHover.matches;

const dev = false; // Prevent Autoplay of carousel and videos, should be off in production

// Feature Carousel

$(document).ready(function () {

  const featureCaro = document.getElementById('featureCaro');
  const cornerDecoration = document.getElementById('cornerDecoration');
  const caroSearch = document.getElementById('caroSearch');
  const caroSearchBox = document.getElementById('caroSearchBox');

  const featureCaroVideos = document.querySelectorAll('.slide-video-el');
  const slideTitles = document.querySelectorAll('.slide-title');

  // Override on slide level: data-splide-interval="5500"

  const splide = new Splide(featureCaro, {
    autoplay: !dev,
    //start: !dev ? 1 : 4,
    type: 'fade',
    speed: 1000,
    interval: 5500,
    arrows: false,
    pauseOnHover: false,
    resetProgress: false,
    rewind: true,
  });

  splide.on('moved', function (newIndex, prevIndex, destIndex) {
    let prevVideo = featureCaroVideos[prevIndex];
    let nextVideo = featureCaroVideos[newIndex];

    // Pause Previous Video and Play Current

    prevVideo.pause();
    //if (!dev) setTimeout(() => nextVideo.play(), 500);
    if (!dev) nextVideo.play();

    // Set Corner Indicator

    cornerDecoration.setAttribute('class', `slide-active-${newIndex}`);

  });

  splide.mount();
  
  // Pause Video and Motion While Search Is Focused with a keyboard

  var Autoplay = splide.Components.Autoplay;

  let mouseDown = false;

  caroSearch.addEventListener('mousedown', () => {
    mouseDown = true;
  });
  
  caroSearch.addEventListener('mouseup', () => {
    mouseDown = false;
  });
  
  caroSearch.addEventListener('focus', (event) => {
    let pausedVideo = featureCaroVideos[splide.index];
    Autoplay.pause();
    pausedVideo.pause();
    if (!mouseDown) {
      caroSearchBox.classList.add('focused');
    }
  });
  
  caroSearch.addEventListener('blur', (event) => {
    let pausedVideo = featureCaroVideos[splide.index];
    Autoplay.play();
    pausedVideo.play();
    caroSearchBox.classList.remove('focused');
  });


  if(slideTitles.length) {
  
    slideTitles.forEach(function(slideTitle) {
      slideTitle.addEventListener('mouseenter', function() {
        console.log('pause');
        Autoplay.pause();
      });

      slideTitle.addEventListener('mouseleave', function() {
        console.log('play');
        Autoplay.play();
      });
  
    });

  }

});

// Stripe Animations

$(document).ready(function () {
  if ($('.stripe-left').length) {
    const stripeLeft = $('.js-stripe-left');
    const stripeRight = $('.js-stripe-right');
    // let mainContainer = $('.js-main-container');
    const stripeContent = $('.js-stripe-content');

    function stripeArrange() {
      //let rightSideSpace = ($(window).width() - $('.js-main-container').innerWidth()) / 2;
      //let main =  (($('.js-main-container').outerWidth(true) - $('.js-main-container').innerWidth()) / 2);

      let stripeContentOffset = stripeContent.get(0).offsetTop;
      let stripeContentHeight = $('.js-stripe-content').height();

      if ($(window).width() >= 1800) {
        stripeLeft.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      } else if ($(window).width() < 1800) {
        stripeLeft.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      }
    }

    stripeArrange();

    $(window).resize(function () {
      stripeArrange();
    });
  }
});

$(document).ready(function () {
  if ($('.stripe-left').length) {
    const stripeLeft = $('.js-stripe-left');
    const stripeRight = $('.js-stripe-right');
    // let mainContainer = $('.js-main-container');
    const stripeContent = $('.js-stripe-content');

    function stripeArrange() {
      //let rightSideSpace = ($(window).width() - $('.js-main-container').innerWidth()) / 2;
      //let main =  (($('.js-main-container').outerWidth(true) - $('.js-main-container').innerWidth()) / 2);

      let stripeContentOffset = stripeContent.get(0).offsetTop;
      let stripeContentHeight = $('.js-stripe-content').height();

      if ($(window).width() >= 1800) {
        stripeLeft.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      } else if ($(window).width() < 1800) {
        stripeLeft.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      }
    }

    stripeArrange();

    $(window).resize(function () {
      stripeArrange();
    });
  }
});
